import { Container } from "react-bootstrap";
import { ActionModal } from "src/components/partials";
// import DisplayItemsOffline from "src/components/partials/controls/DisplayItemsOffline";
import React, { useEffect } from "react";
import { isFunction } from "lodash";
import DisplayItemsOffline from "src/components/partials/controls/display/DisplayItemsOffline";
const { useReducer } = require("react");

const useConfirmation = ({ title, fields, success, onConfirm, extra, onHide, subTitle, hide, setHide,extraDisplay,backButton }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "SHOW_CONFIRMATION_MODAL":
        return {
          ...state,
          formValues: action.payload,
          showConfirmationModal: true,
        };
      case "HIDE_CONFIRMATION_MODAL":
        return {
          ...state,
          showConfirmationModal: false,
        };
      default:
        throw new Error();
    }
  };

  const [state, emit] = useReducer(reducer, {
    formValues: {},
    showConfirmationModal: false,
  });
  useEffect(() => {
    if (hide && setHide && state.showConfirmationModal) {
      emit({ type: "HIDE_CONFIRMATION_MODAL" })
      setHide(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide])

  return confirmationModal({
    title: title,
    subTitle: subTitle,
    extra: extra,
    formValues: state.formValues,
    fields: fields,
    success: success,
    backButton:backButton,
    extraDisplay,
    onHide: () => {
      if (onHide) { onHide() }
      emit({ type: "HIDE_CONFIRMATION_MODAL" })
    },
    show: state.showConfirmationModal,
    showModal: (values) =>
      emit({ type: "SHOW_CONFIRMATION_MODAL", payload: values }),
    onConfirm: onConfirm,
  });
  // return {formValues:state.formValues,onHide:() => emit({ type: "HIDE_CONFIRMATION_MODAL" }),showConfirmationModal:(values)=>(emit({ type: "SHOW_CONFIRMATION_MODAL", payload: values })),show:state.showConfirmationModal};
};

export default useConfirmation;

const confirmationModal = ({
  title,
  formValues = {},
  showModal,
  onConfirm,
  show,
  onHide,
  fields,
  success,
  extra: extra,
  extraDisplay,
  backButton
}) => {


  let modal = (
    <ActionModal
      title={title}
      size="mapi"
      backButton={backButton}
      success={success?.isCreated}
      onHide={onHide}
      body={
        <Container>
          {isFunction(extraDisplay) && extraDisplay()}
          <DisplayItemsOffline fields={fields} object={formValues} />
          {isFunction(extra) && extra(formValues)}
        </Container>
      }
      show={show}
      onClick={() => {
        onConfirm(formValues);
      }}
    />
  );

  if (!onConfirm)
    modal = <Container>
      <DisplayItemsOffline fields={fields} object={formValues} />
    </Container>

  return { modal, showConfirmationModal: showModal };
};
