import { ACTIONS } from "../constants"

const initialState = {
  reclamations: [],
  notifications : [],
  reclamation: {},
  totalSize: 0,
  totalNotifications: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isReseted: false,
    isTreated: false,
    isUndeleted: false,
  },
  error: null,
}

export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.CLEAR_RECLAMATION: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      }
    }

    case ACTIONS.FETCH_RECLAMATIONS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_RECLAMATIONS_SUCCEDED: {
      const { count, results } = payload
      
      return {
        ...state,
        totalSize: count,
        reclamations: results,
        isFetching: false,
        error: null,
      }
    }
    case ACTIONS.FETCH_RECLAMATIONS_FAILED: {
      return { ...state, isLoading: false, isFetching:false, success: false }
    }

    case ACTIONS.RECLAMATIONS_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.RECLAMATIONS_NOTIFICATION_SUCCEDED: {
      const { count, results } = payload
      return {
        ...state,
        totalNotifications: count,
        notifications: results,
        isFetching: false,
        error: null,
      }
    }
    case ACTIONS.RECLAMATIONS_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.READ_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.READ_NOTIFICATION_SUCCEDED: {
      return { ...state, isFetching: false, notifications: state.notifications.map((notification) => notification.id !== payload.id ? notification : ({ ...notification, ...payload}))};
    }
    case ACTIONS.READ_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.CREATE_RECLAMATION_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_RECLAMATION_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_RECLAMATION_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.FCM_TOKEN_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.FCM_TOKEN_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.FCM_TOKEN_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.EDIT_RECLAMATION_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_RECLAMATION_SUCCEDED: {
      return {
        ...state,
        reclamation: payload,
        success: { ...state.success, isTreated: true },
        isLoading: false,
        error: null,
      }
    }
    case ACTIONS.EDIT_RECLAMATION_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_RECLAMATION_INIT: {
      return { ...state, isFetching: true, reclamation: null, error: null }
    }
    case ACTIONS.FETCH_RECLAMATION_SUCCEDED: {
      return { ...state, reclamation: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_RECLAMATION_FAILED: {
      return { ...state, isFetching: false , reclamation :{  "id":1,
        "reclamation_name": "test",
        "reclamation_categorie": "test",
      }
    }
    }

    case ACTIONS.REGENRATE_RECLAMATION_PASSWORD_INIT: {
      return { ...state, isLoading: true, reclamation: null, error: null, success: initialState.success }
    }
    case ACTIONS.REGENRATE_RECLAMATION_PASSWORD_SUCCEDED: {
      return { ...state, success: { ...state.success, isReseted: true }, isLoading: false, error: null }
    }
    case ACTIONS.REGENRATE_RECLAMATION_PASSWORD_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_RECLAMATION_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_RECLAMATION_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_RECLAMATION_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_RECLAMATIONS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_RECLAMATIONS_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_RECLAMATIONS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_RECLAMATION_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DISABLE_RECLAMATION_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_RECLAMATION_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_RECLAMATIONS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DISABLE_RECLAMATIONS_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_RECLAMATIONS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_RECLAMATION_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ENABLE_RECLAMATION_SUCCEDED: {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_RECLAMATION_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_RECLAMATIONS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ENABLE_RECLAMATIONS_SUCCEDED: {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_RECLAMATIONS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.SOFT_DELETE_RECLAMATION_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.SOFT_DELETE_RECLAMATION_SUCCEDED: {
      return { ...state, success: { ...state.success, isUndeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.SOFT_DELETE_RECLAMATION_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.SOFT_DELETE_RECLAMATIONS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.SOFT_DELETE_RECLAMATIONS_SUCCEDED: {
      return { ...state, success: { ...state.success, isUndeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.SOFT_DELETE_RECLAMATIONS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}