import { CALL_API } from './../constants'
import { makeCall, makeDynamicCall, objectToFormData } from './../helpers'
import { PURGE } from 'redux-persist'
import { CHECK_CONNECTIVITY } from './../components/connectivity'

const api = store => next => action => {
  if (!action || !action.type || action.type !== CALL_API) {
    return next(action);
  }

  const {
    actions,
    endpoint,
    method,
    auth,
    params = {},
    isFormData = false,
    returnResponse = false,
    extra = {},
    headers = {},
    useDynamicBaseUrl = false, // Nouveau champ pour déterminer quelle méthode utiliser
  } = action.meta;

  const { token, headerTokenType, audience } = store.getState().common.auth || {};
  let payload = action.payload;

  const dispatch = (type, payload) => next({ type, payload });
  if (actions.init) {
    dispatch(actions.init, params);
  }

  // Gestion des headers d'authentification
  if (auth) {
    let tokenType = headerTokenType ? headerTokenType : 'Bearer';
    headers.Authorization = `${tokenType} ${token}`;
    headers.audience = audience;
  }

  // Gestion des données de type form-data
  if (isFormData) {
    headers['Content-Type'] = 'multipart/form-data';
    payload = objectToFormData(action.payload);
  }

  // Appel API selon la méthode choisie
  const makeApiCall = useDynamicBaseUrl ? makeDynamicCall : makeCall;
  const callArgs = [method, endpoint, payload, headers, params, extra];

 

  makeApiCall(...callArgs)
    .then(resp => {
      if (returnResponse) {
        dispatch(actions.success, resp);
      } else {
        dispatch(actions.success, resp.data);
      }
    })
    .catch(err => {
      if (err.message === 'Network Error') {
        next({
          type: CHECK_CONNECTIVITY,
        });
      }
      if (
        (err.response && err.response.status === 401 && auth) ||
        (err.response && err?.response?.data?.detail?.includes('Token is invalid or expired') && auth)
      ) {
        next({
          type: PURGE,
          key: 'persistedStore',
          result: () => null,
        });
      } else {
        dispatch(actions.fail, err.response || {});
      }
    });
};

export default api;
