export const MONTH = "month"
export const YEAR = "year"




export const GRAPH_STATUS = {
    [MONTH]:  "MONTHS", 
    [YEAR]:  "YEARS",
    

}

  
export const monthYearUIhelper = (intl) => 
  Object.keys(GRAPH_STATUS).map((value) => ({
    value,
    label: intl.formatMessage({ id: GRAPH_STATUS[value] },),
  })) 
    
