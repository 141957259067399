import React, { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import { FieldError, useFieldCSSClasses } from "./FieldError"
import useHideAndShowField from "./hooks/useHideAndShowField"
import { isRLTLang } from "src/i18n"
import { FormattedError } from "../../alerts/FormattedError";
import { getAttr } from "src/helpers"
import { isFunction } from "lodash"
import { isArray, isEmpty, isString } from "lodash"

const PasswordInputField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  unit = '',
  inputGroupClassName = "form-group",
  inputClassName = "form-control",
  backgroundColor = '#FAFAFA',
  type = "text",
  required = false,
  disabled = false,
  maxLength = 256,
  form,
  error,
  hide=false,
  hideOnFields=[],
  relyOn,
  formRepeaterRelyOn=false,
  size = 12,
  placeholder,
  loadValue,
  ...props
}) => {

  const unitStyle = isRLTLang() ? {
    position: "absolute",
    height: '1.125rem',
    minWidth: '1.25rem',
    paddingTop: '.375rem',
    left: '1.375rem'
  } : {
    position: "absolute",
    height: '1.125rem',
    minWidth: '1.25rem',
    paddingTop: '.375rem',
    right: '1.375rem'
  }
//  const [inputs, setInputs] = useState("")
const [showPassword, setShowPassword] = useState(false);
const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name,formRepeaterRelyOn: formRepeaterRelyOn, hideOnFields: hideOnFields, ...props })
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name)

  useEffect(() => {
    let val;
    // let fieldNameArray=[]
    // let item;
    // let fieldName;
    // console.log('hideOnFields', hideOnFields)
    // if (isArray(hideOnFields) && formRepeaterRelyOn) {
    //   console.log('hello')
    //   console.log(field)
    //   console.log(field.name)

    //   const [formRepeaterName,index] = field.name.split('.');
   
    //   console.log(formRepeaterName+"["+index+"]."+relyOn)
    //   fieldName = formRepeaterName+"["+index+"]."+relyOn
    //   hideOnFields.forEach(({ hideOn, condition, notEqual=false }) => {
    //     item = formRepeaterName+"["+index+"]."+hideOn;
    //     fieldNameArray.push(item)
    //   });
    //   console.log({fieldNameArray})
    //   console.log(fieldName)

    //   setInputs(fieldNameArray)
    //   //chainedOptions(setSelectedOptions, getAttr(formik.values,fieldName)); // getAttr dose   all the magic
    // }

    if (isFunction(loadValue)) {
        let relyOnName=null
        let val=null
       if (formRepeaterRelyOn) {
       const [formRepeaterName,index] = field.name.split('.');
       relyOnName = formRepeaterName+"["+index+"]."+relyOn
       val=loadValue(formik.values,relyOnName)
      }
       else {
        val=loadValue(formik.values)
       }
      val = loadValue(formik.values,relyOnName)
      form.setFieldValue(field.name, val)
      formik.setFieldValue(field.name, val)
    }


    //  eslint-disable-next-line
  }, [formik.values])
  return (
    <>
  
    { !hide && <div style={{ width: '100%' }} className={inputGroupClassName + conditionalShowAndHideClassName + ` col-lg-${size}`}>
      {label && <label className="custom-label-style"> {label} <span>{(required && "*")}</span></label>}
      {unit && <div style={unitStyle}  >{unit} </div>}
      <div class="input-group mb-3">

    <input style={{ backgroundColor, height: "40px" }}
        type={showPassword ? "text" : "password"} // Basculer entre "password" et "text"
        disabled={disabled}
        className={`${inputClassName} ${fieldCSSClasses} ${disabled && `disabled`}`}
        maxLength={maxLength}
        autoComplete="off"
        placeholder={placeholder}
        {...field}
        value={field.value || ""}
      />
      <div class="input-group-append">
    <button class="btn btn-outline-secondary"onClick={togglePasswordVisibility} type="button">{showPassword ? "👁️" : "👁️‍🗨️"} </button>
</div>
       </div>

      {error ? <FormattedError message={error} onClose={null} /> : <></>}
      <FieldError fieldName={field.name} />
    </div>}</>
  )
}

export default PasswordInputField
