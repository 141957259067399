const RDV = 1
const EFFECTUER = 2




export const VACCINE_STATUS = {
    [RDV]:  "GENERAL.RDV",
    [EFFECTUER]:  "GENERAL.EFFECTUER"
}

export const DEFAULT_VACCINE_STATUS = "-"
  
export const statusUIHelper = (intl) => {

  let obj = Object.keys(VACCINE_STATUS).map((value) => ({
    value,
    label: intl.formatMessage({ id: VACCINE_STATUS[value] }),
}))

return obj
}