export const RDV=1
export const FAIT=2
export const NON_FAIT=3



export const VACCINE_SCHEMA_STATUS = {
    [RDV]:  "GENERAL.RDV",
    [FAIT]:  "GENERAL.FAIT",
    [NON_FAIT] : "GENERAL.NON_FAIT"
}

export const DEFAULT_VACCINE_STATUS = "-"
  
export const statusVaccUIHelper = (intl) => {

  let obj = Object.keys(VACCINE_SCHEMA_STATUS).map((value) => ({
    value,
    label: intl.formatMessage({ id: VACCINE_SCHEMA_STATUS[value] }),
}))

return obj
}