const CLOTUREE="2"
const CLOTUREE_TEMPORAIREMENT="3"
const EN_COURS="1"




export const SCHEMA_STATUS = {
    [CLOTUREE]:  "GENERAL.CLOTURE",
    [CLOTUREE_TEMPORAIREMENT]:  "GENERAL.CLOTUREE_TEMPORAIREMENT",
    [EN_COURS] : "GENERAL.ENCOURS"
}

export const DEFAULT_VACCINE_STATUS = "-"
  
export const statusSchemaAntirabUIHelper = (intl) => {

  let obj = Object.keys(SCHEMA_STATUS).map((value) => ({
    value,
    label: intl.formatMessage({ id: SCHEMA_STATUS[value] }),
}))

return obj
}

