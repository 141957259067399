/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
// import {  ShowModal } from "shared/components"
//import * as actions from "../../../_redux/users/usersActions"
import _ from "lodash"
import { useReclamationsUIContext } from "../../context/ReclamationsUIContext"
import Reclamation from "../display/Reclamation"
import { fetchReclamation } from "../../store/actions"
import { ShowModal } from "src/components/partials"

const ShowReclamationdetails = ({ params, show, onHide }) => {
  
  const reclamationsUIProps = useReclamationsUIContext()
  
  
  const dispatch = useDispatch()
  const { isLoading, success, reclamation, isFetching, error} = useSelector(
    (state) => ({ isLoading: state.admin.reclamation.isLoading,
         success: state.admin.reclamation.success,
         error: state.admin.reclamation.error,
         reclamation: state.admin.reclamation.reclamation,
         isFetching: state.admin.reclamation.isFetching

         }),
    shallowEqual
  )

  
  useEffect(() => {
    if (!_.isEmpty(params)) {
        dispatch(fetchReclamation(params));
    }
}, [params, dispatch]);

  

  return (
    <ShowModal
    title="Consulter les détails d’une réclamation"
    show={show}
    isFetching={isFetching}
    onHide={onHide}
    print={false}
    size="mapi"
  
  >
    
    <Reclamation error={error} isFetching={isFetching} reclamation={reclamation} />
  </ShowModal>
  )
}


export default ShowReclamationdetails
