export const DARK_THEME = 'dark'
export const LIGHT_THEME = 'light'
export const DARK_BLUE = 3
export const THEME = {
    [DARK_THEME]: "ORGANIZATION.DARK_THEME",
    [LIGHT_THEME]: "ORGANIZATION.LIGHT_THEME",
  //[DARK_BLUE] : "ORGANIZATION.DARK_BLUE_THEME"

}
export const themeUIHelper =(intl)=>
    Object.keys(THEME).map(key=>({
        value: key,
        label: intl.formatMessage({id:THEME[key]})
    }))

export default themeUIHelper
