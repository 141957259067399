import { HTTP_METHODS } from "src/constants"
import { makeCall } from "src/helpers"
import { isRLTLang } from "src/i18n";
import { store } from "src/configureStore";
import _ from "lodash"

const { token, headerTokenType, audience } = store.getState().common.auth || {}

const formatResponse = (items) => items.map((item) => ({
  label: isRLTLang() ? item.valueAr : item.valueFr,
  value: item.rank,
}))


export const categoryUIHelper = (callback = f => f, param) => {
  const FETCH_ENDPOINT = "/api/specialityList"
  const tokenType = headerTokenType ? headerTokenType : 'Bearer'

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `${tokenType} ${token}`, audience: `${audience}` })
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatResponse(result)))
      })
      .catch(err => reject(err.response))

  )
}


const formatOptions = (options) => {
  let values = {}
  options.forEach((option) => {
    values[option.rank] = isRLTLang() ? option.valueAr : option.valueFr
  })
  return values;
}


















