import React from "react";
import { MODULE_CHOICES_COLD_CHAIN, MODULE_CHOICES_E_PHARMACIE, MODULE_CHOICES_EVAX, moduleReclamationUIHelper, roleUIHelper } from "src/modules/admin/UIHelpers";
import { FormattedDate, FormattedTime } from "react-intl";

const PROJECT_LABELS = {
  1: "Evax",
  2: "Chaîne de froid", 
  3: "E-Pharmacie"
};

const NATURE_LABELS = {
  1: "Technique",
  2: "Métier"
};

const CRITICITE_LABELS = {
  1: "Haute",
  2: "Moyenne",
  3: "Basse"
};

export const reclamationFields = ({ intl }) => [
  {
    name: "codeReclamation",
    label: "Numéro de réclamation :",
    size: 6,
  },
  {
    name: "natureReclamation",
    label: "Type de réclamation :",
    size: 6,
    formatter: (value) => NATURE_LABELS[value]
  },
  {
    name: "project",
    label: "Application :",
    size: 6,
    formatter: (value) => PROJECT_LABELS[value]
  },
  {
    name: "moduleSpecifique",
    label: "Module concerné :",
    size: 6,
    formatter: (value, allValues) => {
          if (!value) return "Non spécifié";
          switch(allValues?.project) {
            case 1: return MODULE_CHOICES_EVAX.find(m => m.value === value)?.label || "Non spécifié";
            case 2: return MODULE_CHOICES_COLD_CHAIN.find(m => m.value === value)?.label || "Non spécifié";
            case 3: return MODULE_CHOICES_E_PHARMACIE.find(m => m.value === value)?.label || "Non spécifié";
            default: return "Non spécifié";
          }
        },
      },
  
  {
    name: "statut",
    label: "Statut :",
    size: 6,
  },
  {
    name: "dateTraitement",
    label: "Date de création :",
    size: 6,
    formatter: (value) => {
      
      let date = new Date(value);
      if (date.toString() === "Invalid Date") {
        return <></>;
      }

    return(  <div>
        <FormattedDate value={date} year="numeric" month="long" day="2-digit" />
        {" "}
        <FormattedTime value={date} hour="2-digit" minute="2-digit" />
      </div>)
    }
  },
  {
    name: "updatedAt",
    label: "Dernière mise à jour :",
    size: 6,
    formatter: (value) => {
      let date = new Date(value);
      if (date.toString() === "Invalid Date") {
        return <></>;
      }
      return (<div>
        <FormattedDate value={date} year="numeric" month="long" day="2-digit" />
        {" "}
        <FormattedTime value={date} hour="2-digit" minute="2-digit" />
      </div>
    )}
  },
  {
    name: "description",
    label: "Description de la réclamation :",
    size: 6,
  },
  {
    name: "criticite",
    label: "Criticité :",
    size: 6,
    formatter: (value) => CRITICITE_LABELS[value]
  },
  {
    name: "responsableN1",
    label: "Responsable affecté :",
    size: 6,
  },
  {
    name: "receiverUserReclamation",
    label: "Responsable réassigné :",
    size: 6,
  },
  {
    name: "commentaire",
    label: "Commentaire de résolution :",
    size: 6,
  }
];
