import { ACTIONS, ENDPOINTS } from "../constants"
import { CALL_API, HTTP_METHODS } from "../../../../../../constants"

export const clearStore = () =>
  ({
    type: ACTIONS.CLEAR_RECLAMATION
  })

export const fetchReclamations = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_RECLAMATIONS_INIT,
        success: ACTIONS.FETCH_RECLAMATIONS_SUCCEDED,
        fail: ACTIONS.FETCH_RECLAMATIONS_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATIONS,
      method: HTTP_METHODS.GET,
      params,
      useDynamicBaseUrl: true,
      auth: true
    }
  })

export const fetchNotification = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.RECLAMATIONS_NOTIFICATION_INIT,
        success: ACTIONS.RECLAMATIONS_NOTIFICATION_SUCCEDED,
        fail: ACTIONS.RECLAMATIONS_NOTIFICATION_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATIONS_NOTIFICATION,
      method: HTTP_METHODS.GET,
      params,
      auth: true
    }
  })

export const fcmToken = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.FCM_TOKEN_INIT,
        success: ACTIONS.FCM_TOKEN_SUCCEDED,
        fail: ACTIONS.FCM_TOKEN_FAILED
      },
      endpoint: ENDPOINTS.FCM_TOKEN,
      method: HTTP_METHODS.POST,
      auth: true
    }
  })

export const readNotification = (param) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.READ_NOTIFICATION_INIT,
      success: ACTIONS.READ_NOTIFICATION_SUCCEDED,
      fail: ACTIONS.READ_NOTIFICATION_FAILED,
    },
    auth: true,
    endpoint: ENDPOINTS.READ_NOTIFICATION.replace(":param", param),
    method: HTTP_METHODS.PATCH,
  },
})

export const createReclamation = (payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_RECLAMATION_INIT,
      success: ACTIONS.CREATE_RECLAMATION_SUCCEDED,
      fail: ACTIONS.CREATE_RECLAMATION_FAILED
    },
    endpoint: ENDPOINTS.RECLAMATION_ADD,
    method: HTTP_METHODS.POST,
    useDynamicBaseUrl: true,
    auth: true
  }
})

export const editReclamation = ({ param }, payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.EDIT_RECLAMATION_INIT,
        success: ACTIONS.EDIT_RECLAMATION_SUCCEDED,
        fail: ACTIONS.EDIT_RECLAMATION_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATION.replace(":param", param),
      method: HTTP_METHODS.PUT,
      useDynamicBaseUrl: true,
      auth: true
    }
  })

export const disableReclamation = ({ param }, payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.DISABLE_RECLAMATION_INIT,
        success: ACTIONS.DISABLE_RECLAMATION_SUCCEDED,
        fail: ACTIONS.DISABLE_RECLAMATION_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATION_DEACTIVATE.replace(":param", param),
      method: HTTP_METHODS.POST,
      useDynamicBaseUrl: true,
      auth: true
    }
  })

export const enableReclamation = ({ param }, payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.ENABLE_RECLAMATION_INIT,
        success: ACTIONS.ENABLE_RECLAMATION_SUCCEDED,
        fail: ACTIONS.ENABLE_RECLAMATION_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATION_ACTIVATE.replace(":param", param),
      method: HTTP_METHODS.POST,
      useDynamicBaseUrl: true,
      auth: true
    }
  })

export const disableReclamations = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.DISABLE_RECLAMATIONS_INIT,
        success: ACTIONS.DISABLE_RECLAMATIONS_SUCCEDED,
        fail: ACTIONS.DISABLE_RECLAMATIONS_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATIONS_DEACTIVATE,
      method: HTTP_METHODS.DELETE,
      useDynamicBaseUrl: true,
      params,
      auth: true
    }
  })

export const enableReclamations = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.ENABLE_RECLAMATIONS_INIT,
        success: ACTIONS.ENABLE_RECLAMATIONS_SUCCEDED,
        fail: ACTIONS.ENABLE_RECLAMATIONS_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATIONS_ACTIVATE,
      method: HTTP_METHODS.DELETE,
      useDynamicBaseUrl: true,
      params,
      auth: true
    }
  })

export const regenerateReclamationPassword = ({ param }) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.REGENRATE_RECLAMATION_PASSWORD_INIT,
        success: ACTIONS.REGENRATE_RECLAMATION_PASSWORD_SUCCEDED,
        fail: ACTIONS.REGENRATE_RECLAMATION_PASSWORD_FAILED
      },
      endpoint: ENDPOINTS.REGENRATE_PASSWORD.replace(":param", param),
      method: HTTP_METHODS.POST,
      useDynamicBaseUrl: true,
      auth: true
    }
  })

export const fetchReclamation = ({ param }) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_RECLAMATION_INIT,
        success: ACTIONS.FETCH_RECLAMATION_SUCCEDED,
        fail: ACTIONS.FETCH_RECLAMATION_FAILED
      },
      endpoint: ENDPOINTS.RECLAMATION.replace(":param", param),
      method: HTTP_METHODS.GET,
      useDynamicBaseUrl: true,
      auth: true
    }
  })

export const undeleteReclamation = ({ param }) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.SOFT_DELETE_RECLAMATION_INIT,
      success: ACTIONS.SOFT_DELETE_RECLAMATION_SUCCEDED,
      fail: ACTIONS.SOFT_DELETE_RECLAMATION_FAILED,
    },
    endpoint: ENDPOINTS.RECLAMATION_UNDELETE.replace(":param", param),
    method: HTTP_METHODS.DELETE,
    useDynamicBaseUrl: true,
    auth: true,
  },
})

export const deleteReclamation = ({ param }) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.DELETE_RECLAMATION_INIT,
      success: ACTIONS.DELETE_RECLAMATION_SUCCEDED,
      fail: ACTIONS.DELETE_RECLAMATION_FAILED,
    },
    endpoint: ENDPOINTS.RECLAMATION_DELETE.replace(":param", param),
    method: HTTP_METHODS.DELETE,
    useDynamicBaseUrl: true,
    auth: true,
  },
})

export const undeleteReclamations = (params) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.SOFT_DELETE_RECLAMATIONS_INIT,
      success: ACTIONS.SOFT_DELETE_RECLAMATIONS_SUCCEDED,
      fail: ACTIONS.SOFT_DELETE_RECLAMATIONS_FAILED,
    },
    endpoint: ENDPOINTS.RECLAMATIONS_UNDELETE,
    method: HTTP_METHODS.DELETE,
    useDynamicBaseUrl: true,
    params,
    auth: true,
  },
})

export const deleteReclamations = (params) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.DELETE_RECLAMATIONS_INIT,
      success: ACTIONS.DELETE_RECLAMATIONS_SUCCEDED,
      fail: ACTIONS.DELETE_RECLAMATIONS_FAILED,
    },
    endpoint: ENDPOINTS.RECLAMATIONS_DELETE,
    method: HTTP_METHODS.DELETE,
    useDynamicBaseUrl: true,
    params,
    auth: true,
  },
})