import { injectIntl } from "react-intl"


export const TODAY = 1
export const PAST = 2



export const VACCINATION_TYPE = {
    [TODAY]:  "TODAYVACCIN",
    [PAST]:  "PASTVACCIN",
}
  
export const vaccinationTypeUIHelper = (intl) => 
  Object.keys(VACCINATION_TYPE).map((value) => ({
    value : value,
    label: intl.formatMessage({ id: VACCINATION_TYPE[value] }),
  
}))


