import { injectIntl } from "react-intl"


export const LOCALE = 1
export const GENERAL = 2



export const VACCINATION_LOCATION = {
    [LOCALE]:  "VACCINATION.INPUT.SPOT.LOCAL",
    [GENERAL]:  "VACCINATION.INPUT.SPOT.GENERAL",
}
  
export const voiAddmissionUIHelper = (intl) => 
  Object.keys(VACCINATION_LOCATION).map((value) => ({
    value : Number(value),
    label: intl.formatMessage({ id: VACCINATION_LOCATION[value] }),
  
}))


