
export const MODULE_CHOICES_EVAX = [
    { value: 1, label: "Vaccination" },
    { value: 2, label: "Dashboard" }
  ];
  
 export const MODULE_CHOICES_COLD_CHAIN = [
    { value: 3, label: "Equipements" },
    { value: 4, label: "Suivi de température" },
    { value: 5, label: "Pièces de recharges" },
    { value: 6, label: "Maintenance curative" },
    { value: 7, label: "Maintenance préventive" },
    { value: 8, label: "Pannes" },
    { value: 9, label: "Alertes" },
    { value: 10, label: "Contrat de maintenance" },
    { value: 11, label: "Planification d'inventaire" },
    { value: 12, label: "Inventaire" },
    { value: 13, label: "Estimation des besoins" },
    { value: 14, label: "Fournisseurs externes équipements" },
    { value: 15, label: "Fournisseurs externes maintenance" },
    { value: 16, label: "Gestion des utilisateurs" },
    { value: 17, label: "Gestion des structures" },
    { value: 18, label: "Gestion des dépôts" }
  ];
  
  export const MODULE_CHOICES_E_PHARMACIE = [
    { value: 19, label: "Gestion de l'approvisionnement" },
    { value: 20, label: "Gestion des livraisons" },
    { value: 21, label: "Gestion des entrées" },
    { value: 22, label: "Gestion des sorties" },
    { value: 23, label: "Gestion des pertes" },
    { value: 24, label: "Gestion de la dispensation" },
    { value: 25, label: "Ajustement de stock" },
    { value: 26, label: "Suivi du stock" }
  ];
export const moduleReclamationUIHelper = (callback, project) => {
    let choices = [];
    
    switch (project) {
      case 1: // EVAX
        choices = MODULE_CHOICES_EVAX;
        break;
      case 2: // COLD_CHAIN
        choices = MODULE_CHOICES_COLD_CHAIN;
        break;
      case 3: // E_PHARMACIE
        choices = MODULE_CHOICES_E_PHARMACIE;
        break;
      default:
        choices = [];
    }
  
    return callback ? callback(choices) : choices;

  };
  


