import { HTTP_METHODS } from "src/constants"
import { makeCall } from "src/helpers"
import { store } from "src/configureStore";
import _ from "lodash"

const { token, headerTokenType, audience } = store.getState().common.auth || {}

const getFileMimeType = (filePath) => {
  let type = "pdf"
  if (filePath) {
    const index = filePath.lastIndexOf('.')
    type = filePath.substr(index + 1, filePath.length)
  }
  switch (type) {
    case "png":
      return "image/png"
    case "jpg":
      return "image/jpg"
    case "png":
      return "image/png"
    case "pdf":
      return "application/pdf"
    default:
      return "application/pdf"

  }
}



export const downloadFileUIHelper = (callback = f => f, citizen) => {
  const FETCH_ENDPOINT = "/api/citizen/subscription-request/:param/file-download"
  let tokenType = headerTokenType ? headerTokenType : 'Bearer'

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT.replace(":param", citizen.id), {}, { Authorization: `${tokenType} ${token}`, audience: `${audience}` })
      .then(resp => {
        const data = `data:${getFileMimeType(citizen.supportingFile)};base64,${resp.data}`
        resolve(callback(data))
      })
      .catch(err => reject(err.response))

  )
}





















