import React from "react";
import { roleUIHelper } from "src/modules/admin/UIHelpers";
import { FormattedDate, FormattedTime } from "react-intl";

const PROJECT_LABELS = {
  1: "Evax",
  2: "Chaîne de froid", 
  3: "E-Pharmacie"
};

const NATURE_LABELS = {
  1: "Technique",
  2: "Métier"
};

const CRITICITE_LABELS = {
  1: "Haute",
  2: "Moyenne",
  3: "Basse"
};

export const reclamationTreatmentFields = ({ intl }) => [
  {
    name: "project",
    label: "Application :",
    size: 6,
    formatter: (value) => PROJECT_LABELS[value]
  },
  {
    name: "typeReclamation",
    label: "Type :",
    size: 6,
  },
  {
    name: "natureReclamation",
    label: "Nature :",
    size: 6,
    formatter: (value) => NATURE_LABELS[value]
  },
  {
    name: "criticite",
    label: "Criticité :",
    size: 6,
    formatter: (value) => CRITICITE_LABELS[value]
  },
  {
    name: "description",
    label: "Description de la réclamation :",
    size: 6,
  },
  {
    name: "dateTraitement",
    label: "Date de soumission :",
    size: 6,
    formatter: (value) => {
      let date = new Date(value);
      if (date.toString() === "Invalid Date") {
        return <></>;
      }

        return (
        <div>
          <FormattedDate value={date} year="numeric" month="long" day="2-digit" />
          {" "}
          <FormattedTime value={date} hour="2-digit" minute="2-digit" />
        </div>
      );
    }
  },
  {
    name: "responsableN1",
    label: "Assignée à :",
    size: 6,
  }
];
