import _ from "lodash"

import { ACTIVATE, DEACTIVATE, VIEW, UPDATE, CREATE, SEARCH,REGISTRED, LIST, MODULES_PERMISSIONS, DELETE, UNDELETE, CLOSE } from "./../../../../constants"
//import {isRLTLang} from "../../../i18n"
import {
  ALLERGY,
  CHILDREN,
  REFERENTIEL,
  USERS,
  DASHBOARD,
  GROWTH_METRICS,
  HEAD_CIRCUMFERENCE_METRICS,
  MAPI,
  MEDICAL_FOLDER,
  GROUPS,
  VACCINATION,
  SPECIALTIE,
  DCI,LOT
} from "src/constants/AppPermissions.js";

export const PERMISSIONS_LABELS = {
  [ACTIVATE]: "GENERAL.ACTIVATE",
  [DEACTIVATE]: "GENERAL.DEACTIVATE",
  [VIEW]: "GENERAL.VIEW",
  [UPDATE]: "GENERAL.UPDATE",
  [CREATE]: "GENERAL.CREATE",
  [DELETE]: "GENERAL.DELETE",
  [UNDELETE]: "GENERAL.UNDELETE",
  [SEARCH]: "GENERAL.SEARCH",
  [REGISTRED]: "GENERAL.REGISTRED",
  [LIST]: "GENERAL.LIST",
  [CLOSE]: "GENERAL.CLOSED",
}

export const MODULES_LABELS = {
  [CHILDREN]: "PERMISSIONS.CHILDREN",
  [VACCINATION]: "PERMISSIONS.VACCINATION",
  [DCI]: "PERMISSIONS.DCI",
  [DASHBOARD]: "PERMISSIONS.DASHBOARD",
  [ALLERGY]: "PERMISSIONS.ALLERGY",
  [GROWTH_METRICS]: "PERMISSIONS.GROWTH_METRICS",
  [HEAD_CIRCUMFERENCE_METRICS]: "PERMISSIONS.HEAD_CIRCUMFERENCE_METRICS",
  [MEDICAL_FOLDER]: "PERMISSIONS.MEDICAL_FOLDER",
  [MAPI]: "PERMISSIONS.MAPI",
  [SPECIALTIE]: "PERMISSIONS.SPECIALTIE",
  [LOT]: "PERMISSIONS.LOT",
  [REFERENTIEL]: "PERMISSIONS.REFERENTIEL",
  [USERS]: "PERMISSIONS.USERS",
  [GROUPS]: "PERMISSIONS.GROUPS",
}


export const permissionUIHelper = (callback, selectedPermissions = []) => {

  const permissions = []
  for (let modulePermissionKey in MODULES_PERMISSIONS) {
  for(let module_label in MODULES_PERMISSIONS[modulePermissionKey].module)
   { 
    if (module_label in MODULES_LABELS) {
      let permission = {
        label: MODULES_LABELS[module_label],
        options: []
      }
      for (let permissionKey in MODULES_PERMISSIONS[modulePermissionKey].module[module_label].permissions) { 
        if (
          permissionKey in PERMISSIONS_LABELS &&
          (_.isEmpty(selectedPermissions) || (!_.isEmpty(selectedPermissions)
            && _.includes(selectedPermissions, MODULES_PERMISSIONS[modulePermissionKey].module[module_label].permissions[permissionKey])))
        ) {
          permission.options.push({
            label: PERMISSIONS_LABELS[permissionKey],
            value: MODULES_PERMISSIONS[modulePermissionKey].module[module_label].permissions[permissionKey]
          })
        }
      }

      if (!_.isEmpty(permission.options)) {
        permissions.push(permission)
      }
    }}
  }
  console.log({permissions})
  callback(permissions)
}


export default _.memoize(permissionUIHelper)
