
export const PREMATURED = "2"
export const NORMAL = "normal"


export const TYPE_BIRTH = {
    [PREMATURED]:  "Naissance prématurée",
    [NORMAL]:  "normal",

}

export const DEFAULT_TYPE_BIRTH = "0"
  
export const BirthTypeUIHelper = (intl) =>
  Object.keys(TYPE_BIRTH).map((value) => ({
    value : value,
    label: intl.formatMessage({ id: TYPE_BIRTH[value] }),
}))

// const options = [
//   { value: 'Naissance prématurée', label: 'Naissance prématurée', disabled: false },
//   { value: 'option2', label: 'Naissance normal', disabled: false },
// ];

// const BirthTypeUIHelper = (intl) => {
//   return (
//     <RadioButtonGroup
//       options={options}
//       defaultValue="option1"
//     />
//   );
// };

// export default BirthTypeUIHelper;