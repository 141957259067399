import React, {useEffect, useRef, useState} from "react"
import _ from "lodash"
import * as Yup from "yup"
import { Formik, Form, FastField } from "formik"
import { Row } from "react-bootstrap"

import { FORM_COMPONENT, DEFAULT_TYPE } from "./../types/inputTypes"
import { createYupSchema, getStorage, setStorage } from "../../../../../helpers"
import {useControlUISelector} from "../.."
import {ControlUIProvider} from "../../store"

const DynamicForm = (props) => {

  const {
    ref,
    children,
    fields = [],
    className,
    saveForm=false,
    error,
    clearValuesAfterSubmit,
    saveFormName="",
    validationSchema,
    reset = false,
    onChange,
    filterForm = false,
    saveRef,
    onSubmit,
    initialValues = {},
    user
  } = props


  const [savedValues, setSavedValues] = useState({})
  const resetRef = useRef()

  const formUiProps = useControlUISelector()

  useEffect(() => {
    if (!_.isEmpty(fields) && formUiProps){
      formUiProps.setFormFields(fields)
    }
    // eslint-disable-next-line
  }, [fields])

  useEffect(() => {
    if (!_.isEmpty(error) && formUiProps){
      formUiProps.setError(error)
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if (!_.isEmpty(error) && formUiProps){
      formUiProps.setError(error)
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if (saveForm){
      setSavedValues(getStorage(saveFormName))
    }

    // eslint-disable-next-line
  }, [])

  const fieldsValidation = React.useMemo(() =>  {
    if (!_.isEmpty(formUiProps.shownFormFields)){
      return createYupSchema(formUiProps.shownFormFields)
    }
  }, [formUiProps.shownFormFields])

  const renderField = _.memoize(({ name, component, ...props },suffix) => (
    <FastField
      key={name + suffix}
      name={name}
      component={FORM_COMPONENT[component] || DEFAULT_TYPE }
      { ...props }
    />
  ))

  let suffix = 0;
  const renderFields = React.useMemo(()=> !_.isEmpty(fields) && (<Row>
    
    {
     fields.map((input, i) => {
      suffix ++;
      const {  validation, ...field } = input
      return (
          renderField(field,suffix) 
      )
    })}
  </Row>), [fields, renderField])

  const initialSnapshot = React.useMemo(() => {
    const snapshot = {}
    formUiProps.formFields.forEach(field => {
      if (!field.name.includes("[]")) {
        _.set(snapshot, field.name, _.get(initialValues, field.name, _.get(field, "initialValue", "")) )
      }
    })
    return snapshot

    // eslint-disable-next-line
  }, [formUiProps.formFields, initialValues])

  const handleSubmit = async (values) => {
    await onSubmit(values);
    formUiProps.setIsSubmitted(true);
    // resetForm(); // Reset the form after successful submission
  };
  const handleSubmitUser = async (values, { resetForm }) => {
    await onSubmit(values); // Submit the form
    formUiProps.setIsSubmitted(true); // Set the submission flag
    resetForm(); // Reset the form to its initial state
  };
  
  
  

  const handleReset = () => {
    if (filterForm){
      handleSubmit(Object.assign(initialSnapshot, _.isEmpty(initialValues) ? savedValues : initialValues))
    }
  }

  useEffect(() => {
    if (clearValuesAfterSubmit && !reset){
      if (saveForm){
        setStorage(saveFormName, {})
      }
      resetRef && resetRef.current.click()
    }
    // eslint-disable-next-line
  }, [clearValuesAfterSubmit, reset])

  return (
    <div ref={ref}>
 {  user ?( <Formik
      enableReinitialize={true}
      onSubmit={handleSubmitUser}
      onChange={onChange}
      onReset={handleReset}
      validationSchema={Yup.isSchema(validationSchema) ? validationSchema : fieldsValidation }
      initialValues={ Object.assign(initialSnapshot, _.isEmpty(initialValues) ? savedValues : initialValues) }
      validateOnChange={true}
      validateOnBlur={true}
    >
      
      {(form) => {
        if (saveForm && _.isEmpty(initialValues) && form.dirty) {
          setStorage(saveFormName, form.values);
        }
        return (
          <Form className={className} onSubmit={form.handleSubmit}>
            { renderFields }
            { children }
            { saveRef && <button ref={saveRef} className="d-none" type="submit"></button> }
            <button ref={resetRef} className="d-none" type="reset"></button>

          </Form>
        );
      }}
    </Formik>):(
      <Formik
      enableReinitialize={true}
      onSubmit={handleSubmit}
      onChange={onChange}
      onReset={handleReset}
      validationSchema={Yup.isSchema(validationSchema) ? validationSchema : fieldsValidation }
      initialValues={ Object.assign(initialSnapshot, _.isEmpty(initialValues) ? savedValues : initialValues) }
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(form) => {
        if (saveForm && _.isEmpty(initialValues) && form.dirty) {
          setStorage(saveFormName, form.values);
        }
        return ( 
          <Form className={className} onSubmit={form.handleSubmit}>

            { renderFields }
            { children }
            { saveRef && <button ref={saveRef} className="d-none" type="submit"></button> }
            <button ref={resetRef} className="d-none" type="reset"></button>
          </Form>
        );
      }}
    </Formik>
    )}

    </div>
  )
}


const DynamicFormProvider = ({ children, ...props }) => <ControlUIProvider><DynamicForm { ...props }>{ children }</DynamicForm></ControlUIProvider>

export default React.memo(DynamicFormProvider)
