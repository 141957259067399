import React from "react"
import Typography from "@material-ui/core/Typography"
import RenderItemsOff from "./render/RenderItemsOff"
const DisplayItemsOffline = ({ fields = [], title, children, show=true, error, isFetching=false, object }) => {
    return (
      <>        { title && <Typography className="mt-5">{ title }</Typography> }
        { fields && <RenderItemsOff fields={fields} object={object} show={show} /> }
        {
          React.Children.map(children, (child, i) => (
            <React.Fragment key={i}>{ React.cloneElement(child, { ...child.props, isFetching, object }) }</React.Fragment>          ))
        }
      </>    )
}
export default React.memo(DisplayItemsOffline)