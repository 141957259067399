import React, { useEffect, useState } from "react";
import { injectIntl } from 'react-intl'

import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { ModalProgressBar } from "shared/components";
import { disableReclamation, fetchReclamation, fetchReclamations } from "../../store/actions";
// import {useConfirmation} from "shared/hooks"
import * as Yup from 'yup';
import { useReclamationsUIContext } from "../../context/ReclamationsUIContext"
import { reclamationFields } from "../display/fields/reclamationFields";
import { ModalProgressBar } from "src/components/partials";
import useConfirmation from "src/hooks/useConfirmation";

const ReclamationEnableDialog = ({ params, show, onHide, intl}) => {
  const reclamationsUIProps = useReclamationsUIContext()
  const schema = Yup.object().shape({
    reason: Yup.string().required('Ce champ est obligatoire'),
  });
  
  const dispatch = useDispatch();
  const { isLoading, success, reclamation } = useSelector(
    (state) => ({
      isLoading: state.admin.reclamation.isLoading,
      success: state.admin.reclamation.success,
      reclamation: state.admin.reclamation.reclamation,
    }),
    shallowEqual
  );

  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setReason(e.target.value);
    setError('');
  };

  const f = reclamationFields({intl});

  const { modal, showConfirmationModal } = useConfirmation({
    title: "Confirmation",
    success: success,
    fields: f,
  });

  useEffect(() => {
    if (reclamation) {
      showConfirmationModal(reclamation);
    }
  }, [reclamation]);

  useEffect(() => {
    if (show) {
      dispatch(fetchReclamation(params));
    }
    return () => {
      setReason("");
    };
  }, [show]);

  useEffect(() => {
    if (success.isDeactivated && show) {
      onHide();
      dispatch(fetchReclamations(reclamationsUIProps.queryParams));
      reclamationsUIProps.setIds([]);
    }
  }, [success, show]);

  const onEnableUser = async () => {
    try {
      await schema.validate({ reason });
      dispatch(disableReclamation(params,{motif:reason}));
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      size="lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
        Désactiver cette réclamation 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Êtes-vous sûr de vouloir désactiver cette réclamation ?
        {modal}
         <div style={{ width: '100%' }} className="container form-group col-lg-12 mt-4">
        <label className="custom-label-style">Motif <span>*</span></label>
        <input style={{ backgroundColor:"#FAFAFA", height: "40px" }}
        className={`form-control`}
        autoComplete="off"
        value={reason}
        onChange={handleChange}
        placeholder="Motif"
      />
            {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-sm btn-light btn-elevate"
          >
            Annuler
          </button>
          <> </>
          <button
            type="button"
            disabled={isLoading}
            onClick={onEnableUser}
            className="btn btn-sm btn-primary btn-elevate"
          >
            {isLoading && <span className="px-5 spinner spinner-white"></span>}
            Confirmer
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default injectIntl(ReclamationEnableDialog);
