import { lazy } from "react"
import { combinePathRoutes } from "src/helpers"
// import ResetPhoneNumber from "src/modules/authentication/containers/ResetPhoneNumber";
import routes from "src/routes"
 
const SSOHome = lazy(() => import("./../SSOHome"))
const ResetPassword = lazy(() => import("../../../../authentication/containers/ResetPassword"));
const ResetPhoneNumber = lazy(() => import("../../../../authentication/containers/ResetPhoneNumber"))
const ProfiledDisplay = lazy(() => import("../components/ProfileDisplay"))
const ReclamationShow = lazy(() => import("../../../../admin/containers/reclamation/ReclamationShow"))
const ReclamationPage = lazy(() => import("../../../../admin/containers/reclamation/ReclamationPage"))
const Reclamation = lazy(() => import("../../../../admin/containers/reclamation/Reclamation"))

const SSOhome = {
  path: "/home",
  component: SSOHome,
  exact: true,
}
const resetPassword = {
  path: "/reset-password",
  component: ResetPassword,
  hideforCnom: true
}
const resetPhone = {
  path: "/reset-phone",
  component: ResetPhoneNumber,
  hideforCnom: true
}

const profileDisplay = {
  path: "/profile/display",
  component: ProfiledDisplay
}


 const reclamationCreate = {
  path: "/reclamations/new",
  component: Reclamation,
  exact: true,
}

 const reclamationEdit = {
  path: "/reclamations/:param/edit",
  component: Reclamation,
}

 const reclamationShow = {
  path: "/reclamations/:param/show",
  component: ReclamationShow,
}

 const reclamationList = {
  path: "/reclamations",
  component: ReclamationPage,
}
const path = routes.ssoLayout.path
export default combinePathRoutes({ path }, {
  resetPassword,
  resetPhone,
  profileDisplay,
  SSOhome,
  reclamationCreate,
  reclamationEdit,
  reclamationShow,
  reclamationList
})
