
import { HTTP_METHODS } from "src/constants"
import { makeCall } from "src/helpers"
import { store } from "src/configureStore";
import {isEmpty} from "lodash"
import rootRoutes from "src/routes"

const { token, headerTokenType, audience } = store.getState().common.auth || {}

const formatResponse = (items) => items.map((item) => ({ 
  label: item.commercialName, 
  value: item.id,
}))
export const comercialNameAntirabUIHelper = (callback,param) => {
  if (!param || isEmpty(param.toString())){
    callback([])
    return
  }
  const FETCH_ENDPOINT = "/api/speciality_list?dci=:param".replace(':param',param)
  const tokenType = headerTokenType ? headerTokenType : 'Bearer'

  return new Promise((resolve, reject) =>
  makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `${tokenType} ${token}`, audience: `${audience}` })
    .then(resp => resolve(callback(formatResponse(resp.data))))
    .catch((err) => { 
      if (err.response && err.response.status === 401){
        window.location.href=rootRoutes.logout.path
        return
      }
      return reject(callback([]) )})
    
  )
}