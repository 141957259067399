export const RECLAMATIONS = "/sc/ms5/api/reclamations"
export const RECLAMATION = "/sc/ms5/api/reclamation/:param"
export const RECLAMATION_ADD = "/sc/ms5/api/reclamation/create"
export const RECLAMATION_EDIT = "/sc/ms3/api/reclamation/:param/edit"

export const RECLAMATION_ACTIVATE = "/sc/ms3/api/reclamation/:param/activate"
export const RECLAMATION_DEACTIVATE = "/sc/ms3/api/reclamation/:param/deactivate"

export const RECLAMATION_DELETE = "/sc/ms3/api/reclamations/:param/delete"
export const RECLAMATION_UNDELETE = "/sc/ms3/api/reclamations/:param/undelete"

export const RECLAMATIONS_ACTIVATE = "/sc/ms3/api/reclamations/activate"
export const RECLAMATIONS_DEACTIVATE = "/sc/ms3/api/reclamations/deactivate"

export const RECLAMATIONS_UNDELETE = "/sc/ms3/api/reclamations/undelete"
export const RECLAMATIONS_DELETE = "/sc/ms3/api/reclamations/delete"
