import routes from "../../home/routes/ssoPages"
import { combinePathRoutes } from "src/helpers"
import ReclamationEnableDialog from "../components/dialog/ReclamationEnableDialog"
import ReclamationDisableDialog from "../components/dialog/ReclamationDisableDialog"
import ShowReclamationTreatmentdetails from "../components/dialog/ShowReclamationTreatmentdetails"
import ShowReclamationdetails from "../components/dialog/ShowReclamationdetails"

const reclamationEnable = {
  path: "/enable-reclamation/:param",
  component: ReclamationEnableDialog,
}

const reclamationDisable = {
  path: "/disable-reclamation/:param",
  component: ReclamationDisableDialog,
}
const reclamationTreatmentShow = {
  path: "/reclamation-treatment/:param",
  component: ShowReclamationTreatmentdetails,
}

const reclamationShow = {
  path: "/show-reclamation/:param",
  component: ShowReclamationdetails,
}

export default combinePathRoutes(
  {
    path: routes.reclamationList.path
  },
  {
    reclamationEnable,
    reclamationDisable,
    reclamationShow,
    reclamationTreatmentShow
  }
)
