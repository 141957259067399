export const JAN = 1;
export const FEB = 2;
export const MARS = 3;
export const APRIL = 4;
export const MAY = 5;
export const JUNE = 6;
export const JULLY = 7;
export const AUGU = 8;
export const SEP = 9;
export const OCT = 10;
export const NOV = 11;

export const MONTH = {
  [JAN]: "1",

  [FEB]: "2",

  [MARS]: "3",

  [APRIL]: "4",
  [MAY]: "5",

  [JUNE]: "6",

  [JULLY]: "7",
  [AUGU]: "8",

  [SEP]: "9",

  [OCT]: "10",

  [NOV]: "11",

};

const monthUIHelper = () =>
  Object.keys(MONTH).map((key) => ({
    value: key,
    label: MONTH[key],
  }));

export default monthUIHelper;
