
export const VACC_CENTER_TYPE=1
export const CIRCONSCRIPTION_TYPE=2
export const REGEONAL_DIRECTION_TYPE=3
export const DSSB_TYPE=4

export const STRUCTURE_TYPE = { 
  [VACC_CENTER_TYPE]: "HOME.CENTER",
  [CIRCONSCRIPTION_TYPE]: "HOME.CIRCONSCRIPTION",
  [REGEONAL_DIRECTION_TYPE]: "HOME.GENERAL.DIRECTION",
  [DSSB_TYPE]: "DSSB",
}

export const structureTypeUIHelper = (intl) =>
  Object.keys(STRUCTURE_TYPE).map((value) => ({
    value: parseInt(value),
    label: intl.formatMessage({ id: STRUCTURE_TYPE[value] }),
  }))
