import { isRLTLang } from "src/i18n";


const formatResponse = (data) => {
  return [{
    label: isRLTLang() ? data.nameAr : data.nameFr,
    value: data.id,
    ...data
  }]
}

export const centerFormatterUIHelper = (centerDetails) =>formatResponse(centerDetails)

