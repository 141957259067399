import React, { createContext, useContext, useState, useCallback } from "react"
import { isEqual, isFunction } from "lodash"
import {IS_ACTIVE, IS_ACTIVE_INIT_VALUE} from "../store/constants"

const ReclamationsUIContext = createContext()

export function useReclamationsUIContext() {
  return useContext(ReclamationsUIContext)
}

export const ReclamationsUIConsumer = () => ReclamationsUIContext.Consumer

export function ReclamationsUIProvider({ reclamationsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState({})
  const [ids, setIds] = useState([])
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])
  
  const [reclamationsQueryParams, setReclamationsQueryParamsBase] = useState({})
  const setReclamationsQueryParams = useCallback((nextQueryParams) => {
    setReclamationsQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    reclamationsQueryParams,
    setReclamationsQueryParams,
    ...reclamationsUIEvents
  }

  return (
    <ReclamationsUIContext.Provider value={value}>
      {children}
    </ReclamationsUIContext.Provider>
  )
}
