import { HTTP_METHODS } from "src/constants"
import { makeCall } from "src/helpers"
import { isRLTLang } from "src/i18n";
import { store } from "src/configureStore";
import rootRoutes from "src/routes"

const formatResponse = (items) => items.map((item) => ({ 
  label: isRLTLang() ? item.labelDelgAr : item.labelDelgFr, 
  value: item.idDelg,
  ...item
}))
export const delegationUIHelper = (callback,param) => {
  const { token, headerTokenType, audience } = store.getState().common.auth || {}
  const tokenType = headerTokenType ? headerTokenType : 'Bearer'

  if (!param){
    callback([])
    return
  }
  const FETCH_ENDPOINT = "/api/citizen/governorate/:param/delegetions/list".replace(':param',param)
  return new Promise((resolve, reject) =>
  makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `${tokenType} ${token}`, audience: `${audience}` } )
    .then(resp => resolve(callback(formatResponse(resp.data))))
    .catch((err) => { 
      if (err.response && err.response.status === 401){
        window.location.href=rootRoutes.logout.path
        return
      }
      return reject(callback([]) )})
    
  )
}