
export const ONE = 1
export const TOW = 2
export const THREE = 3
export const FOUR = 4
export const FIVE = 5


export const PYEAR = {
  [ONE]: 1,
  [TOW]: 2,
  [THREE]: 3,
  [FOUR]: 4,
  [FIVE]: 5
}

 const yearUIHelper = () =>
  Object.keys(PYEAR).map((key) => ({
    value: key,
    label: PYEAR[key]
  }))

export default yearUIHelper
