import { lazy } from "react"
import {MODULES_PERMISSIONS, CREATE, UPDATE, VIEW} from "src/constants"
import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"

const ReclamationShow = lazy(() => import("../ReclamationShow"))
const ReclamationPage = lazy(() => import("../ReclamationPage"))
const Reclamation = lazy(() => import("../Reclamation"))

export const reclamationCreate = {
  path: "/reclamations/new",
  component: Reclamation,
  exact: true,
}

export const reclamationEdit = {
  path: "/reclamations/:param/edit",
  component: Reclamation,
}

export const reclamationShow = {
  path: "/reclamations/:param/show",
  component: ReclamationShow,
}

export const reclamationList = {
  path: "/reclamations",
  component: ReclamationPage,
  exact: true, 
}

const path = routes.ssoLayout.path
export default combinePathRoutes({ path }, {
  reclamationList,
  reclamationCreate,
  reclamationShow,
  reclamationEdit,
})
