// RECLAMATION
export const FETCH_RECLAMATIONS_INIT = "RECLAMATION@FETCH_RECLAMATIONS_INIT"
export const FETCH_RECLAMATIONS_SUCCEDED = "RECLAMATION@FETCH_RECLAMATIONS_SUCCEDED"
export const FETCH_RECLAMATIONS_FAILED = "RECLAMATION@FETCH_RECLAMATIONS_FAILED"

export const CLEAR_RECLAMATION = "RECLAMATION@CLEAR_RECLAMATION"

export const CREATE_RECLAMATION_INIT = "RECLAMATION@CREATE_RECLAMATION_INIT"
export const CREATE_RECLAMATION_SUCCEDED = "RECLAMATION@CREATE_RECLAMATION_SUCCEDED"
export const CREATE_RECLAMATION_FAILED = "RECLAMATION@CREATE_RECLAMATION_FAILED"

export const REGENRATE_RECLAMATION_PASSWORD_INIT = "RECLAMATION@REGENRATE_RECLAMATION_PASSWORD_INIT"
export const REGENRATE_RECLAMATION_PASSWORD_SUCCEDED = "RECLAMATION@REGENRATE_RECLAMATION_PASSWORD_SUCCEDED"
export const REGENRATE_RECLAMATION_PASSWORD_FAILED = "RECLAMATION@REGENRATE_RECLAMATION_PASSWORD_FAILED"

export const FETCH_RECLAMATION_INIT = "RECLAMATION@FETCH_RECLAMATION_INIT"
export const FETCH_RECLAMATION_SUCCEDED = "RECLAMATION@FETCH_RECLAMATION_SUCCEDED"
export const FETCH_RECLAMATION_FAILED = "RECLAMATION@FETCH_RECLAMATION_FAILED"

export const FCM_TOKEN_INIT = "RECLAMATION@FCM_TOKEN_INIT"
export const FCM_TOKEN_SUCCEDED = "RECLAMATION@FCM_TOKEN_SUCCEDED"
export const FCM_TOKEN_FAILED = "RECLAMATION@FCM_TOKEN_FAILED"

export const RECLAMATIONS_NOTIFICATION_INIT = "RECLAMATION@RECLAMATIONS_NOTIFICATION_INIT"
export const RECLAMATIONS_NOTIFICATION_SUCCEDED = "RECLAMATION@RECLAMATIONS_NOTIFICATION_SUCCEDED"
export const RECLAMATIONS_NOTIFICATION_FAILED = "RECLAMATION@RECLAMATIONS_NOTIFICATION_FAILED"

export const READ_NOTIFICATION_INIT = "notification@READ_NOTIFICATION_INIT"
export const READ_NOTIFICATION_SUCCEDED = "notification@READ_NOTIFICATION_SUCCEDED"
export const READ_NOTIFICATION_FAILED = "notification@READ_NOTIFICATION_FAILED"

export const EDIT_RECLAMATION_INIT = "RECLAMATION@EDIT_RECLAMATION_INIT"
export const EDIT_RECLAMATION_SUCCEDED = "RECLAMATION@EDIT_RECLAMATION_SUCCEDED"
export const EDIT_RECLAMATION_FAILED = "RECLAMATION@EDIT_RECLAMATION_FAILED"

export const DISABLE_RECLAMATION_INIT = "RECLAMATION@DISABLE_RECLAMATION_INIT"
export const DISABLE_RECLAMATION_SUCCEDED = "RECLAMATION@DISABLE_RECLAMATION_SUCCEDED"
export const DISABLE_RECLAMATION_FAILED = "RECLAMATION@DISABLE_RECLAMATION_FAILED"

export const ENABLE_RECLAMATION_INIT = "RECLAMATION@ENABLE_RECLAMATION_INIT"
export const ENABLE_RECLAMATION_SUCCEDED = "RECLAMATION@ENABLE_RECLAMATION_SUCCEDED"
export const ENABLE_RECLAMATION_FAILED = "RECLAMATION@ENABLE_RECLAMATION_FAILED"

export const DISABLE_RECLAMATIONS_INIT = "RECLAMATION@DISABLE_RECLAMATIONS_INIT"
export const DISABLE_RECLAMATIONS_SUCCEDED = "RECLAMATION@DISABLE_RECLAMATIONS_SUCCEDED"
export const DISABLE_RECLAMATIONS_FAILED = "RECLAMATION@DISABLE_RECLAMATIONS_FAILED"

export const ENABLE_RECLAMATIONS_INIT = "RECLAMATION@ENABLE_RECLAMATIONS_INIT"
export const ENABLE_RECLAMATIONS_SUCCEDED = "RECLAMATION@ENABLE_RECLAMATIONS_SUCCEDED"
export const ENABLE_RECLAMATIONS_FAILED = "RECLAMATION@ENABLE_RECLAMATIONS_FAILED"

export const DELETE_RECLAMATION_INIT = "RECLAMATION@DELETE_RECLAMATION_INIT"
export const DELETE_RECLAMATION_SUCCEDED = "RECLAMATION@DELETE_RECLAMATION_SUCCEDED"
export const DELETE_RECLAMATION_FAILED = "RECLAMATION@DELETE_RECLAMATION_FAILED"

export const SOFT_DELETE_RECLAMATION_INIT = "RECLAMATION@SOFT_DELETE_RECLAMATION_INIT"
export const SOFT_DELETE_RECLAMATION_SUCCEDED = "RECLAMATION@SOFT_DELETE_RECLAMATION_SUCCEDED"
export const SOFT_DELETE_RECLAMATION_FAILED = "RECLAMATION@SOFT_DELETE_RECLAMATION_FAILED"

export const DELETE_RECLAMATIONS_INIT = "RECLAMATION@DELETE_RECLAMATIONS_INIT"
export const DELETE_RECLAMATIONS_SUCCEDED = "RECLAMATION@DELETE_RECLAMATIONS_SUCCEDED"
export const DELETE_RECLAMATIONS_FAILED = "RECLAMATION@DELETE_RECLAMATIONS_FAILED"

export const SOFT_DELETE_RECLAMATIONS_INIT = "RECLAMATION@SOFT_DELETE_RECLAMATIONS_INIT"
export const SOFT_DELETE_RECLAMATIONS_SUCCEDED = "RECLAMATION@SOFT_DELETE_RECLAMATIONS_SUCCEDED"
export const SOFT_DELETE_RECLAMATIONS_FAILED = "RECLAMATION@SOFT_DELETE_RECLAMATIONS_FAILED"