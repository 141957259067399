import React from 'react'
import { injectIntl } from 'react-intl'
// import {
//   DisplayItems,
//   RenderItems
// } from 'shared/components'

import { reclamationFields} from './fields/reclamationFields'
import { DisplayItems, RenderItems } from 'src/components/partials'

const Reclamation = ({ reclamation = {}, error, isFetching, intl }) => {
  const fields = reclamationFields({ intl })

  return (
        <DisplayItems
          error={error}
          isFetching={isFetching}
          object={reclamation}
        >
          <RenderItems fields={fields} />
        </DisplayItems>
  )
}

export default injectIntl(Reclamation)
