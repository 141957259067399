import { injectIntl } from "react-intl"


export const ARM = 1
export const LEG = 2



export const VACCINATION_LOCATION = {
    [ARM]:  "VACCINATION.INPUT.SPOT.ARM",
    [LEG]:  "VACCINATION.INPUT.SPOT.LEG",
}
  
export const vaccineLocationUIHelper = (intl) => 
  Object.keys(VACCINATION_LOCATION).map((value) => ({
    value : value,
    label: intl.formatMessage({ id: VACCINATION_LOCATION[value] }),
  
}))


