import React from 'react'
import { memoize } from 'lodash'
import { DEFAULT_ITEM, DEFAULT_ITEM_OFF, ITEM_COMPONENT } from './../item-types/item-types'
import { Row } from 'react-bootstrap'
const RenderItemsOff = ({ fields, show = true, isFetching = false, object }) => {

  const renderField = memoize(({ component, ...props }) => {
    const Component = ITEM_COMPONENT[component] || DEFAULT_ITEM_OFF
    return (
      <Component field={props} isFetching={isFetching} object={object} />    )
  })
  return (
    <Row className={show ? '' : ' d-none'}>      {fields.map((field, i) => (
        <React.Fragment key={i}>{renderField(field)}</React.Fragment>      ))}
    </Row>  )
}
export default React.memo(RenderItemsOff)