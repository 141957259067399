export const WHEIGTFORAGE = "weight_for_age"
export const TAILLEFORAGE = "taille_for_age"
export const WHEIGTFORTAILL = "weight_for_taille"




export const GRAPH_STATUS = {
    [WHEIGTFORAGE]:  "GRAPHCHART_WHEIGTFORAGE", 
    [TAILLEFORAGE]:  "GRAPHCHART_TAILLEFORAGE",
    [WHEIGTFORTAILL]:  "GRAPHCHART_WHEIGTFORTAILL",
    

}

export const DEFAULT_GRAPH_STATUS = "GRAPHCHART_MONTH"
  
export const graphUIhelper = (intl) => 
  Object.keys(GRAPH_STATUS).map((value) => ({
    value,
    label: intl.formatMessage({ id: GRAPH_STATUS[value] }),
  })) 
    
