import { HTTP_METHODS } from "src/constants"
import { makeCall, makeDynamicCall } from "src/helpers"
import { isRLTLang } from "src/i18n";
import { store } from "src/configureStore";
// import { LogoutExpiredToken } from "../general/LogoutExpiredToken";
import {isEmpty} from "lodash"
const FETCH_ENDPOINT =  "/sc/ms5/api/type-reclamations"

// const EVAX = "1"
// const COLD_CHAIN = "2"
// const E_PHARMACIE = "3"
// const PARAM = {
//   [EVAX]:15,
//   [COLD_CHAIN]:22,
//   [E_PHARMACIE]:17,
// }

const formatResponse = (items) => {
  return items.map((item) => ({
    value: item.id,
    label: item.nomReclamation
  }));
};

export const reclamationUIhelper = (callback = f => f, param) => {
  if (!param || isEmpty(param.toString())){
    callback([])
    return
  }
  const { token } = store.getState().common.auth || {}
  return new Promise((resolve, reject) =>
    makeDynamicCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `Bearer ${token}` })
      .then(resp => {
        resolve(callback(formatResponse(resp.data)))
      })
      .catch((error) =>{
        // LogoutExpiredToken(error)
        resolve(callback([]))
      })
  )
}


// const formatResponse = (items) => {
//   const formattedItems = items.map((item) => {
//     return {
//       value: item.rank,
//       label: item?.valueFr,

//     }
//   }
//   );
//   return formattedItems;
// };
// export const reclamationUIhelper = (callback = f => f,param) => {
//   if (!param || isEmpty(param.toString())){
//     callback([])
//     return
//   }
//   const { token } = store.getState().common.auth || {}
//   return new Promise((resolve, reject) =>
//     makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT.replace(":param",PARAM[`${param}`]), { _pagination: false }, { Authorization: `Bearer ${token}` })
//       .then(resp => {
//         resolve(callback(formatResponse(resp.data.optionSet)))

//       })
//       .catch((error) =>{
//         LogoutExpiredToken(error)
//         resolve(callback([]))
//       })
//   )
// }