export const ROUTINE_VACCINATION = 1
export const SEASONAL_VACCINATION = 2
export const VACCINATION_FOR_MOBILITY = 3
export const RABIES_VACCINATION = 4
export const OCCASIONAL_VACCINATION = 5
export const SERUM = 6
export const GRIP = "Grippe"
export const COVID = "COVID"


export const VACCINE_CATEGORY = {
  [ROUTINE_VACCINATION]: "VACCINE.CATEGORY.ROUTINE_VACCINATION",
  [SEASONAL_VACCINATION]: "VACCINE.CATEGORY.SEASONAL_VACCINATION",
  [VACCINATION_FOR_MOBILITY]: "VACCINE.CATEGORY.VACCINATION_FOR_MOBILITY",
  [RABIES_VACCINATION]: "VACCINE.CATEGORY.RABIES_VACCINATION",
  [OCCASIONAL_VACCINATION]: "VACCINE.CATEGORY.OCCASIONAL_VACCINATION",
  [SERUM]: "VACCINATION.SERUM"

  

}
export const vaccinationCatgoryUIHelper = (intl) =>
  Object.keys(VACCINE_CATEGORY).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: VACCINE_CATEGORY[key] }),
  }))
