export const CLOTUREE="CLOTUREE"
export const CLOTUREE_TEMPORAIREMENT="CLOTUREE_TEMP"
export const EN_COURS="EN_COURS"




export const FICHE_STATUS = {
    [CLOTUREE]:  "ANTIRABIES_FICHE_STATUS.CLOTURE",
    [CLOTUREE_TEMPORAIREMENT]:  "ANTIRABIES_FICHE_STATUS.CLOTUREE_TEMPORAIREMENT",
    [EN_COURS] : "ANTIRABIES_FICHE_STATUS.ENCOURS"
}

export const DEFAULT_VACCINE_STATUS = "-"
  
export const statusFicheAntirabUIHelper = (intl) => {

  let obj = Object.keys(FICHE_STATUS).map((value) => ({
    value,
    label: intl.formatMessage({ id: FICHE_STATUS[value] }),
}))

return obj
}

