import { HTTP_METHODS } from "./../../../../constants"
import { makeCall } from "./../../../../helpers"
import { store } from "./../../../../configureStore"
import { isRLTLang } from "src/i18n"
import rootRoutes from "src/routes"

const FETCH_ROLES_ENDPOINT = "api/Groups/all"
const { token } = store.getState().common.auth || {}

const formatResponse = (items) => items.map((item) => ({ 
  label: isRLTLang() ? item.nameAr : item.nameFr, 
  value: item.id,
  ...item
}))

export const roleUIHelper = (callback,param) => {
  if (!param){
    callback([])
    return
  }
  const FETCH_ROLES_ENDPOINT = "api/groups?search=:param".replace(':param',param)
  const { token, headerTokenType, audience } = store.getState().common.auth || {}
  const tokenType = headerTokenType ? headerTokenType : 'Bearer'

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ROLES_ENDPOINT, { _pagination: false }, {'Authorization': `${tokenType} ${token}`, audience: `${audience}`} )
    .then(resp => {
      // console.log('result',resp.data.results)
      
      resolve(callback(formatResponse( resp.data.results )))})
      .catch((err) => { 
        if (err.response && err.response.status === 401){
          window.location.href=rootRoutes.logout.path
          return
        }
        return reject(callback([]) )})
    
  )
}
